#root,
body,
html {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.App {
  overflow: hidden !important;
  /* display: flex !important;
  flex-flow: column nowrap !important;
  position: absolute !important;
  inset: 0px !important; */
  width: 100%;
  height: 100%;
}

.main {
  display: flex !important;
  flex-flow: column nowrap !important;
  height: 100% !important;
  width: 100% !important;
}

body {
  min-height: initial;
  color: #1a1a1a;
}

.build-door__box__wrapper {
  display: flex;
  flex-direction: column;
}

.footer-panel__price.disabled {
  display: none;
}

.build__bottpanel.stage-2.hide-btn .build__bottpanel-btnfs {
  display: none;
}

.build__title-header.mb-none.mb {
  margin-bottom: 18px;
}

.main-title.main-title-stage.favourite-style h1 {
  text-align: center;
}

.build__bottpanel.bg-black {
  background-color: #000;
}

.build__bottpanel.functional-btn-hidden .functional-btn {
  display: none;
}

.dimensions-popup.open {
  display: block !important;
}

.thankyou-box {
  display: flex;
  flex-direction: column;
  max-width: 430px;
  margin: auto;
  padding: 15px;
}

.thankyou-box-title {
  margin-top: 100px;
  font-size: 35px;
  text-align: center;
  margin-bottom: 50px;
  letter-spacing: 0.07em;
  line-height: 40px;
}

.thankyou-box-text {
  max-width: 335px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 20px;
  margin-bottom: 50px;
  letter-spacing: 0.07em;
  line-height: 30px;
}

.thankyou-box-btn {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}

.thankyou-box-btn a {
  width: 100%;
  max-width: 100%;
  text-align: left;
  background-color: #22b573;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.184' height='9.603' viewBox='0 0 13.184 9.603'%3E%3Cg id='Сгруппировать_661' data-name='Сгруппировать 661' transform='translate(-509.604 -837.017)'%3E%3Cpath id='Контур_489' data-name='Контур 489' d='M522.483,842.123H509.909a.3.3,0,0,1,0-.61h12.574a.3.3,0,1,1,0,.61Z' fill='%23fff'/%3E%3Cpath id='Контур_490' data-name='Контур 490' d='M522.483,842.123a.3.3,0,0,1-.216-.09l-4.5-4.5a.3.3,0,0,1,.431-.431l4.5,4.5a.305.305,0,0,1-.216.521Z' fill='%23fff'/%3E%3Cpath id='Контур_491' data-name='Контур 491' d='M517.987,846.62a.3.3,0,0,1-.216-.52l4.5-4.5a.3.3,0,0,1,.431.431l-4.5,4.5A.3.3,0,0,1,517.987,846.62Z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 24px center;
}

.thankyou-box-description {
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.07em;
  line-height: 20px;
  max-width: 300px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.thankyou-box-email {
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.07em;
  line-height: 20px;
  max-width: 300px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.thankyou-box-email a {
  color: #000;
}

.thankyou-box-phone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.thankyou-box-phone a {
  color: #000;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.07em;
  line-height: 20px;
}

.thankyou-box-address {
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.07em;
  line-height: 20px;
}

.scrollable-area {
  position: relative;
  height: 100%;
  width: 100%;
}

.simplebar-content-wrapper::-webkit-scrollbar {
  display: none;
}

.main-box-content__inner-container {
  overflow-y: initial;
  overflow-x: initial;
}

.main-box-content__inner-container.overflow {
  overflow: hidden;
}

.efficiency-info__wrapper {
  padding-bottom: 25px;
}

.build__items-inner.owl-mobile {
  display: none;
}

.energyEfficiency__wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.build-door__box-item-link {
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 19.2px;
  font-weight: 350;
  min-height: 48px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.build-door__box-item-link::before {
  display: none;
}

.build-btn {
  top: 50%;
}

.favourite-style-banner .favourite-style-banner-img {
  top: 0px;
  margin-bottom: 50px;
}

.favourite-style-banner__title {
  text-align: center;
}

.favourite-style-banner__title svg {
  margin-top: 20px;
}

.favourite-style-footer-text {
  font-size: 30px;
  letter-spacing: 0.01em;
  line-height: 36px;
  max-width: 1020px;
  margin-top: 50px;
}

.favourite-style-footer-link {
  max-width: 911px;
}

.favourite-style-footer-link a {
  border-top: none;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  padding-right: 0px;
}

.favourite-style-footer-link a .favourite-style-footer-link-img {
  height: 200px;
  width: 100%;
  background-color: #333;
}

.favourite-style-footer-link a .favourite-style-footer-link-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.favourite-style-footer-link span {
  border-top: 1px solid #999;
  padding-top: 5px;
  margin-top: 10px;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 16.8px;
}

.favourite-style-footer-link span::before {
  content: '';
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.94' height='8.738' viewBox='0 0 9.94 8.738'%3E%3Cline id='Линия_109' data-name='Линия 109' x2='8.865' transform='translate(0.445 4.368)' fill='none' stroke='%23333' stroke-linecap='round' stroke-miterlimit='10' stroke-width='0.891'/%3E%3Cline id='Линия_110' data-name='Линия 110' x2='3.735' y2='3.738' transform='translate(5.575 0.63)' fill='none' stroke='%23333' stroke-linecap='round' stroke-miterlimit='10' stroke-width='0.891'/%3E%3Cline id='Линия_111' data-name='Линия 111' y1='3.739' x2='3.735' transform='translate(5.575 4.369)' fill='none' stroke='%23333' stroke-linecap='round' stroke-miterlimit='10' stroke-width='0.891'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  width: 11px;
  height: 8px;
  right: 0;
}

.favourite-style-footer-link a::before {
  display: none;
}

.favourite-style-banner-img::after {
  position: absolute;
  content: '';
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  bottom: 50%;
  background-color: #e6e6e6;
}

.favourite-style-footer {
  border-bottom: none;
  background-color: #333;
  padding: 0px 20px;
  min-height: 50px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.favourite-style-footer-backbtn {
  font-size: 9px;
  letter-spacing: 0.26em;
  line-height: 10.8px;
  color: #fff;
}

.main-box-content__inner.column {
  flex-direction: column;
}

.main-box-content__inner-list.topPanel {
  max-width: 100%;
  min-height: 85px;
  padding: 0px 20px;
}

.main-box-content__inner-list.topPanel .main-box-content__inner__warpper {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.energy-efficiency-panel {
  width: 100%;
}

.energy-efficiency-panel ul {
  display: flex;
  align-items: center;

  justify-content: center;
}

.energy-efficiency-panel ul li + li {
  margin-top: 0px;
  margin-left: 40px;
}

.energy-efficiency-panel ul li {
  max-width: 200px;
  width: 100%;
}

.energy-efficiency-panel ul li a {
  border-top: 0.44px solid #000;
  padding-top: 3px;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 16.8px;
  white-space: nowrap;
}

.energy-efficiency-panel ul li a:hover {
  font-weight: 500;
}

.energy-efficiency-panel ul li a::before {
  top: 50%;
  transform: translateY(-50%);
}

.efficiency-info__first-item-con__inner {
  max-width: 975px;
}

.efficiency-info-title {
  font-size: 40px;
  letter-spacing: 0.03em;
  line-height: 48px;
  text-align: center;
  font-weight: 350;
  padding: 0px 15px;
}

.efficiency-info-text {
  text-align: center;
}

.efficiency-info-text-inner {
  max-width: 655px;
  width: 100%;
  margin: 75px auto 100px;
  padding: 0 15px;
}

.efficiency-info-text-inner .efficiency-info-text {
  font-size: 20px;
  letter-spacing: 0.01em;
  line-height: 24px;
}

.efficiency-info-text p {
  margin-bottom: 17px;
}

.efficiency-info-text-mb {
  margin-bottom: 55px;
}

.efficiency-info__second__inner {
  padding: 0px 15px;
}

.efficiency-info__third {
  padding: 0px 15px;
}

.efficiency-info__first-item.height .efficiency-info__first-item-img {
  height: 420px;
}

.efficiency-info__second-img-box.height .efficiency-info__second-img {
  height: 420px;
}

.efficiency-info__second-img-box.height .efficiency-info__second-img img {
  height: 100%;
  object-fit: cover;
}

.efficiency-info__third.margin {
  margin: 75px auto;
}

.efficiency-info-text-inner.mb-0 {
  margin-bottom: 0px;
}

.efficiency-info__first-item-img.order-none {
  order: 0 !important;
}

.main-box-content__inner-list-listbox li a {
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 19.2px;
  text-align: left;
  color: #999;
  transition: all 0.3s;
}

.main-box-content__inner-list-listbox li a:hover {
  font-weight: 350;
  color: #000;
}

.build-door__box-item-footer-link .build-door__link-prise {
  margin-left: 0px;
  margin-top: 5px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.29em;
  line-height: 16.8px;
  font-weight: 350;
  padding: 12px 20px 12px 20px;
  background-color: #c7b299;
}

.build-door__box-item-footer-link .build-door__link-house {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.29em;
  line-height: 16.8px;
  font-weight: 350;
  padding: 12px 20px 12px 20px;
}

.build-door__box-item-footer-link .build-door__link-prise::before,
.build-door__box-item-footer-link .build-door__link-house::before {
  display: none;
}

.build-door__box-item-footer-link {
  flex-direction: column;
}

.build-door__box-item-name {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 16.8px;
  color: #1a1a1a;
}

.main-box-asite__inner {
  padding: 0px;
  justify-content: space-between;
  height: 100%;
}

.main-box-asite__inner .back-home:last-child {
  margin-bottom: 25px;
}

.summary__panel .summary__panel-item {
  max-width: 100%;
  border: none;
  background-color: #000;
  font-size: 9.07px;
  letter-spacing: 0.13em;
  line-height: 10.89px;
  padding: 0px 15px;
  min-height: 70px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.summary__panel .summary__panel-item.active {
  background-color: #21b070;
}

.summary__panel .summary__panel-item:hover {
  background-color: #21b070;
}

.summary__panel .summary__panel-item:first-child {
  border: none;
}

.summary__panel .summary__panel-item a:hover {
  color: #fff;
}

.summary__title {
  font-size: 34px;
  letter-spacing: 0.07em;
  line-height: 40.8px;
  padding: 25px 15px;
}

.summary__panel {
  margin: 28px 0px;
  margin-bottom: auto;
}

.build__title-mobile.build__title-mobile--active {
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 19.2px;
  color: #1a1a1a;
  max-width: 100%;
  padding: 25px 20px;
}

.build__bottpanel .build__bottpanel-btnfs button {
  background-image: none;
}

.build__bottpanel.stage-2 .build__bottpanel-btnfs {
  text-align: center;
  display: none;
}

.build__bottpanel.stage-2 .build__bottpanel-btnfs button {
  font-weight: 350;
  font-size: 33.57px;
  line-height: 40.28px;
  width: 42.83px;
  height: 42.83px;
  border-radius: 100%;
  padding: 0px;
  position: relative;
}

.build__bottpanel.stage-2 .build__bottpanel-btnfs button::before,
.build__bottpanel.stage-2 .build__bottpanel-btnfs button::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 2px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.build__bottpanel.stage-2 .build__bottpanel-btnfs button::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.build__bottpanel .build__bottpanel-btnsummary {
  display: none;
}

.summary__img-box {
  position: relative;
}

.summary__img-box-btnNext {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 86.19px;
  height: 81.95px;
  background-color: #21b070;
  border: none;
  font-size: 14.22px;
  letter-spacing: 0.44em;
  line-height: 17.07px;
  font-weight: 350;
  color: #fff;
  transition: all 0.3s;
}

.summary__img-box-btnNext:hover {
  background-color: #000;
}

.build__bottpanel a {
  font-size: 12px;
  letter-spacing: 0.26em;
  line-height: 10.8px;
}

.build__bottpanel.stage-2 .btn-2 {
  font-size: 9px;
  letter-spacing: 0.26em;
  line-height: 10.8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.summary__panel-mobile {
  background-color: #000;
  align-items: center;
  justify-content: space-between;
  min-height: 35px;
  padding: 0px 15px;
  display: none;
}

.summary__panel-mobile__item a {
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 16.8px;
  color: #808080;
}

.summary__panel-mobile__item {
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
}

.summary__panel-mobile__item.active a {
  color: #fff;
}

.summary__panel-mobile__item.active::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8.32px 8.32px 8.32px;
  border-color: transparent transparent #ffffff transparent;
}

.preview-door {
  top: 88px;
  bottom: 35px;
}

.build__bottpanel.stage-2 {
  z-index: 5;
}

.preview-door__title {
  font-size: 24.93px;
  letter-spacing: 0.02em;
  line-height: 29.92px;
  text-align: center;
  color: #1a1a1a;
  margin-top: 15px;
  font-weight: 350;
}

.preview-door__img {
  padding: 15px 25px 10px 25px;
}

.preview-door__label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: 0.08em;
  line-height: 16.8px;
  margin-bottom: 25px;
}

.preview-door__label-text.green {
  color: #21b070;
}

.header__menu-dropdown {
  z-index: 10;
}

.build__items-inner {
  grid-template-columns: repeat(3, 1fr);
}

.build__items-inner.low-height .build__item .build__item-img {
  height: 150px;
}

.build__items-inner.medium-height .build__item .build__item-img {
  height: 240px;
}

.build__items-inner.contain .build__item .build__item-img {
  height: auto;
}

.build__items-inner.contain {
  gap: 65px 65px;
  padding: 30px 70px;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.build__items-inner.contain .build__item .build__item-name {
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 16.8px;
}

.build__items-inner.contain .build__item:first-child .build__item-name {
  text-align: left;
}

.build__items-inner.contain .build__item:last-child .build__item-name {
  text-align: right;
}

.build__items-inner.contain .build__item .build__item-img img {
  object-fit: contain;
}

.overlay-builder-validation {
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.overlay-builder-validation .overlay-builder {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-builder-validation .overlay-builder .modal-warning {
  background-color: #c7b299;
  display: inline-block;
  padding: 15px;
  text-align: center;
}

.overlay-builder-validation .overlay-builder .modal-warning .content {
  color: #fff;
  margin-bottom: 15px;
}

.overlay-builder-validation .overlay-builder .modal-warning a {
  background-color: #fff;
  color: #000;
  padding: 8px 33px;
}

.details-form__inner-text {
  background-color: #000;
  max-width: 100%;
  padding: 25px 90px 90px;
  margin-top: auto;
}

.details-form__inner-text p {
  font-size: 9px !important;
  line-height: 10.8px !important;
  color: #fff;
  padding-left: 0px;
  position: relative;
}

.details-form__inner-text p span {
  color: #fff;
}

.details-form__inner-text p:last-child {
  font-size: 7px;
  line-height: 8.4px;
}

.details-form__inner-text p:first-child {
  position: relative;
}

.details-form__inner-text p.icon::before {
  content: '';
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.939' height='16.939' viewBox='0 0 16.939 16.939'%3E%3Cpath id='Контур_186' data-name='Контур 186' d='M12314.3,2639.35a7.651,7.651,0,1,1,7.65-7.65,7.659,7.659,0,0,1-7.65,7.65' transform='translate(-12305.832 -2623.23)' fill='%23fff'/%3E%3Cpath id='Контур_187' data-name='Контур 187' d='M12314.3,2624.869a6.831,6.831,0,1,1-6.831,6.831,6.831,6.831,0,0,1,6.831-6.831m0-1.639a8.469,8.469,0,1,0,8.471,8.47,8.479,8.479,0,0,0-8.471-8.47' transform='translate(-12305.832 -2623.23)' fill='%2321b070'/%3E%3Cpath id='Контур_188' data-name='Контур 188' d='M12318.466,2628.8a.638.638,0,0,0-.885,0l-4.448,4.448-2.1-2.1a.637.637,0,1,0-.916.884l.016.016,2.547,2.546a.635.635,0,0,0,.9,0l4.9-4.9a.638.638,0,0,0-.017-.9' transform='translate(-12305.832 -2623.23)' fill='%2321b070'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  left: -25px;
  top: 0;
  width: 17px;
  height: 17px;
}

.details-form__inner-text::before {
  display: none;
}

.details-form__form-input input,
.details-form__form-input select {
  border: none;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.11em;
  line-height: 12px;
}

.details-form__form-input select {
  background-color: #e6e6e6;

  width: 100%;
  height: 25px;
  padding: 0px 10px;
}

.details-form__form-input.error input {
  border: 1px solid #f00;
  color: #f00;
}

.details-form__form-input.error select {
  border: 1px solid #f00;
  color: #f00;
}

.details-form__form-input_description {
  font-size: 10px;
  color: #666;
  margin-bottom: 10px;
}

.error.details-form__form-input_description {
  color: #f00;
}

.details-form__form-input input::placeholder {
  font-size: 10px;
  letter-spacing: 0.11em;
  line-height: 12px;
  color: #808080;
}

.details-form__form-input textarea {
  border: none;
}

.details-form__form-input + .details-form__form-input {
  margin-top: 10px;
}

.details-form {
  height: 100%;
}

.details-form__inner-box {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.details-form__btn {
  margin-top: 20px;
  text-align: center;
}

.details-form__btn button {
  background-color: #21b070;
  max-width: 320px;
  width: 100%;
  font-size: 13px;
  letter-spacing: 0.29em;
  line-height: 15.6px;
  padding: 12px 10px;
}

.panel-title {
  font-size: 14px;
  letter-spacing: 0.29em;
  line-height: 16.8px;
  color: #fff;
  background-color: #000;
  padding: 10px;
  text-align: center;
}

.furniture-stack__wrapper {
  padding: 30px 40px;
}

.furniture-stack-box {
  display: flex;
  flex-direction: column;
}

.furniture-stack-box-item + .furniture-stack-box-item {
  margin-top: 20px;
}

.furniture-stack-box-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 15px;
  background-color: rgba(199, 178, 153, 0.36);
}

.furniture-stack-item-info {
  margin-right: 10px;
}

.furniture-stack-item-info-title {
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 19.2px;
  margin-bottom: 10px;
}

.furniture-stack-item-info-btn button {
  background-color: transparent;
  border: none;
  font-size: 14px;
  letter-spacing: 0.08em;
  line-height: 16.8px;
  text-align: left;
  color: #21b070;
}

.furniture-stack-box-item-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  grid-auto-rows: 110px;
  max-width: 350px;
  width: 100%;
}

.furniture-stack-box-item-gallery-img:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 3;
}

.furniture-stack-box-item-gallery-img:nth-child(2) {
  grid-column-start: 5;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

.furniture-stack-box-item-gallery-img:nth-child(3) {
  grid-column-start: 1;
  grid-column-end: 3;
}

.furniture-stack-box-item-gallery-img {
  height: 100%;
  width: 100%;
}

.furniture-stack-box-item-gallery-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.furniture-stack-box-item-gallery.second
  .furniture-stack-box-item-gallery-img:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 2;
}

.furniture-stack-box-item-gallery.second
  .furniture-stack-box-item-gallery-img:nth-child(2) {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
}

.furniture-stack-box-item-gallery.second
  .furniture-stack-box-item-gallery-img:nth-child(3) {
  grid-column-start: 5;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

.furniture-stack-box-item-gallery.second
  .furniture-stack-box-item-gallery-img:nth-child(4) {
  grid-column-start: 1;
  grid-column-end: 3;
}

.furniture-stack-btn {
  margin-top: 25px;
  text-align: center;
}

.furniture-stack-btn button {
  background-color: #333;
  font-size: 9px;
  letter-spacing: 0.26em;
  line-height: 10.8px;
  max-width: 250px;
  padding: 12px 30px 14px 30px;
}

.thankyou-box-footer {
  background-color: #666;
  color: #fff;
  padding: 35px 15px 50px;
}

.thankyou-box-email a {
  color: #fff;
}

.thankyou-box-phone a {
  color: #fff;
}

.summary__panel.summary__panel--hiden {
  display: none;
}

.build__bottpanel {
  width: 100%;
  min-height: 100px;
  margin-top: 0px;
}

.build__bottpanel.bg-gray {
  background-color: #333;
}

.thankyou-box-title {
  margin-top: 50px;
}

.summary__img.summary-preview.gallery
  .summary-preview-gallery__popup
  .summary-preview-gallery__popup-inner {
  max-width: 440px;
}

.summary-preview-gallery__popup-inner .global-btn.global-btn-arrow {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.29em;
  line-height: 16.8px;
  font-weight: 350;
  margin-bottom: 0px;
}

.summary-preview-gallery__popup-inner
  .global-btn.global-btn-arrow
  input[type='file'] {
  cursor: pointer;
}

.summary-preview-gallery__popup-inner
  .global-btn.global-btn-arrow
  + .global-btn.global-btn-arrow {
  margin-top: 30px;
}

.summary__img.summary-preview.gallery
  .summary-preview-gallery__popup
  .summary-preview-gallery__popup-inner
  button {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.29em;
  line-height: 16.8px;
  font-weight: 350;
  background-color: #32a8ce;
  margin-bottom: 0px;
}

.summary-preview-gallery__popup-inner .global-btn.global-btn-arrow::before {
  display: none;
}

.summary-btn-box {
  max-width: 330px;
  width: 100%;
  margin: auto auto 0;
  padding-bottom: 35px;
  padding-left: 15px;
  padding-right: 15px;
}

.summary-btn-box .global-btn {
  font-size: 14px;
  letter-spacing: 0.29em;
  line-height: 16.8px;
  text-align: center;
  text-decoration: none;
  margin-bottom: 25px;
  font-weight: 350;
}

.summary-btn-box .global-btn.blue {
  background-color: #32a8ce;
}

.summary-btn-box .global-btn::before {
  display: none;
}

.build__bottpanel .build__bottpanel-btnfs {
  max-width: 100%;
}

.furniture-stack-item-info-btn {
  max-width: 80px;
}

.build__items-inner.contain .build__item {
  padding: 15px 0px;
}

.summary__img.summary-preview.gallery.mobile {
  display: none;
}

.simple-bar__wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.build__items.height-auto {
  height: auto;
}

.simple-bar__inner {
  display: flex;
  flex-direction: column;
}

.details-form__inner-text.mobile {
  display: none;
}

.panel-title.mobile {
  display: none;
}

.share-my-door-btn {
  display: none;
}

.details-form-title-mob {
  font-size: 24.93px;
  line-height: 29.92px;
  text-align: center;
  color: #1a1a1a;
  padding: 12px 15px 14px;
  display: none;
}

.please-send-btn-mob {
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 19.2px;
  text-align: center;
  color: #fff;
  background-color: #21b070;
  padding: 13px 15px 13px;
  position: relative;
  display: none;
}

.please-send-btn-mob::before,
.please-send-btn-mob::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.247' height='16.281' viewBox='0 0 13.247 16.281'%3E%3Cpath id='Контур_137' data-name='Контур 137' d='M15148.287,590.247V574.72a.423.423,0,0,1,.841,0v15.527a.423.423,0,0,1-.841,0' transform='translate(-15142.083 -574.343)' fill='%23fff'/%3E%3Cpath id='Контур_138' data-name='Контур 138' d='M15148.287,590.247a.356.356,0,0,1,.123-.266l6.2-5.552a.456.456,0,0,1,.595,0,.349.349,0,0,1,0,.532l-6.2,5.552a.456.456,0,0,1-.595,0,.356.356,0,0,1-.123-.266' transform='translate(-15142.083 -574.343)' fill='%23fff'/%3E%3Cpath id='Контур_139' data-name='Контур 139' d='M15142.083,584.7a.357.357,0,0,1,.124-.266.456.456,0,0,1,.595,0l6.2,5.551a.35.35,0,0,1,0,.533.456.456,0,0,1-.595,0l-6.2-5.552a.357.357,0,0,1-.124-.266' transform='translate(-15142.083 -574.343)' fill='%23fff'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  width: 13px;
  height: 16px;
}

.please-send-btn-mob::before {
  left: 20px;
}

.please-send-btn-mob::after {
  right: 20px;
}

.details-form-door-preview {
  display: none;
}

.thankyou-box-logo {
  display: none;
  margin-top: 30px;
  text-align: center;
}

.see-inside-view {
  display: none;
  text-align: center;
}

.see-inside-view button {
  background-color: transparent;
  border: none;
  font-size: 10px;
  letter-spacing: 0.11em;
  line-height: 12px;
}

.build__bottpanel .build__bottpanel-btnback {
  height: 100%;
  align-items: center;
  justify-content: center;
}

.build__bottpanel.stage-2 .btn-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.bild-door-box {
  display: flex;
  height: 100%;
}

.owl-nav button {
  bottom: 5px;
}

.main-box-content__inner {
  width: 100%;
}

.main-box {
  width: 100%;
}

.header {
  width: 100%;
}

.window-system-title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 25px;
  color: #334543;
  line-height: 30px;
  text-align: center;
}

.window-system-img {
  margin-bottom: 15px;
}

.window-system-img img {
  margin: auto;
}

.window-system-btn a {
  color: #fff;
  background-color: #000;
  padding: 15px 15px 15px 10px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  width: 100%;
  text-align: center;
}

.window-system-btn a::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(270deg);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.247' height='16.281' viewBox='0 0 13.247 16.281'%3E%3Cpath id='Контур_137' data-name='Контур 137' d='M15148.287,590.247V574.72a.423.423,0,0,1,.841,0v15.527a.423.423,0,0,1-.841,0' transform='translate(-15142.083 -574.343)' fill='%23fff'/%3E%3Cpath id='Контур_138' data-name='Контур 138' d='M15148.287,590.247a.356.356,0,0,1,.123-.266l6.2-5.552a.456.456,0,0,1,.595,0,.349.349,0,0,1,0,.532l-6.2,5.552a.456.456,0,0,1-.595,0,.356.356,0,0,1-.123-.266' transform='translate(-15142.083 -574.343)' fill='%23fff'/%3E%3Cpath id='Контур_139' data-name='Контур 139' d='M15142.083,584.7a.357.357,0,0,1,.124-.266.456.456,0,0,1,.595,0l6.2,5.551a.35.35,0,0,1,0,.533.456.456,0,0,1-.595,0l-6.2-5.552a.357.357,0,0,1-.124-.266' transform='translate(-15142.083 -574.343)' fill='%23fff'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  width: 14px;
  height: 18px;
  margin-left: 15px;
}

.window-system-box {
  margin-bottom: 50px;
}
.build-door__box-item-img {
  overflow: hidden;
}
.build-door__box-item
  .build-door__box-item-img
  > .build-door__box-item-img-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.build-door__box-item
  .build-door__box-item-img
  > .build-door__box-item-img-overlay
  img {
  object-fit: contain;
  height: 100%;
}

.main-title.main-title-stage.favourite-style{
  background-color: #009A74;
}
@media (max-width: 1024px) {
  .window-system-btn a {
    padding: 15px 50px 15px 10px;
    font-size: 15px;
  }

  .window-system-title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }

  .details-form__inner-text {
    padding: 30px 45px;
  }

  .build__items-inner.contain {
    padding: 30px 15px;
  }

  .furniture-stack-box-item-gallery {
    max-width: 100%;
  }

  .furniture-stack-item-info-btn {
    max-width: 100%;
  }

  .furniture-stack-item-info-title {
    margin-bottom: 0px;
  }

  .furniture-stack-box-item {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .furniture-stack-item-info {
    order: 1;
    margin-right: 0px;
    margin-top: 10px;
  }

  .summary__img-box-btnNext {
    width: 75.19px;
    height: 65.95px;
    font-size: 12.22px;
  }

  .build-door__box-item-footer-link .build-door__link-house {
    font-size: 8.23px;
    letter-spacing: 0.11em;
    line-height: 9.87px;
  }

  .build-door__box-item-footer-link .build-door__link-prise {
    font-size: 8.23px;
    letter-spacing: 0.11em;
    line-height: 9.87px;
  }

  .efficiency-info-title {
    font-size: 30px;
    line-height: 35px;
  }

  .efficiency-info-text-inner {
    margin: 25px auto 25px;
  }

  .efficiency-info-text-inner .efficiency-info-text {
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 17px;
  }

  .efficiency-info__first-item:nth-child(odd) .efficiency-info__first-item-img {
    order: 0;
  }

  .efficiency-info__first-item {
    flex-direction: row;
  }

  .thankyou-box-title {
    margin-top: 50px;
    font-size: 25px;
    text-align: center;
    margin-bottom: 25px;
    line-height: 30px;
  }

  .thankyou-box-text {
    margin-bottom: 25px;
    font-size: 15px;
    line-height: 20px;
  }

  .thankyou-box-btn {
    margin-bottom: 25px;
  }

  .thankyou-box-description {
    margin-bottom: 15px;
  }

  .thankyou-box-email {
    margin-bottom: 15px;
  }

  .thankyou-box-phone {
    margin-bottom: 15px;
  }

  .favourite-style-footer-text {
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .summary-btn-box {
    padding-bottom: 0px;
  }

  .thankyou-box-title {
    margin-top: 0px;
    font-size: 20px;
    line-height: 25px;
  }

  .furniture-stack__wrapper {
    padding: 10px 15px 30px;
  }

  .build__items-inner {
    grid-template-columns: repeat(2, 1fr);
  }

  .build__title-mobile.build__title-mobile--active {
    font-size: 10px;
    letter-spacing: 0.11em;
    line-height: 12px;
    padding: 10px;
  }

  .summary__title {
    padding: 14px 15px;
  }

  .main-box-asite.constructor {
    display: flex;
  }

  .main-box-asite.constructor .simplebar-content {
    height: auto;
  }

  .main-box-asite.constructor .back-home {
    display: none;
  }

  .main-box-asite.constructor .summary__panel {
    flex-direction: row;
    margin: 0px;
    width: 100%;
  }

  .main-box.constructor {
    flex-direction: column;
  }

  .favourite-style-banner .favourite-style-banner-img {
    margin-bottom: 0px;
    padding: 0px;
  }

  .efficiency-info__second-img-box.height .efficiency-info__second-img {
    height: 200px;
  }

  .efficiency-info__first-item.height .efficiency-info__first-item-img {
    height: 200px;
  }

  .energy-efficiency-panel ul {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .energy-efficiency-panel ul li {
    max-width: 49%;
    margin-top: 20px;
  }

  .energy-efficiency-panel ul li + li {
    margin-left: 0px;
    margin-top: 20px;
  }

  .efficiency-info__first-item-con__inner {
    padding: 0px 0px 20px 0px;
  }

  .efficiency-info__wrapper {
    padding-top: 0px;
  }

  .efficiency-info__first-item:nth-child(odd) .efficiency-info__first-item-img {
    order: -1;
  }

  .efficiency-info-title {
    font-size: 25px;
    line-height: 30px;
    margin-top: 30px;
  }

  .main-box-content__inner-list.topPanel {
    order: 1;
    min-height: auto;
    padding: 20px;
  }

  .favourite-style-footer-link a + a {
    margin-top: 0px;
  }

  .favourite-style-footer-link a {
    width: 49%;
    margin-bottom: 20px;
  }

  .favourite-style-footer-link {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .efficiency-info__third {
    padding-bottom: 0px;
  }

  .header__menu .menu__list li {
    border-bottom: 1px solid #cccccc2e;
  }

  .header__menu .menu__list li:first-child {
    border-top: 1px solid #cccccc2e;
  }

  .simplebar-scrollbar:before {
    opacity: 0;
  }
}

@media (max-width: 600px) {
  .build__items-inner.duplicate {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .summary__con-inner {
    max-width: 100%;
  }

  .see-inside-view {
    display: block;
  }

  .panel-title.mobile.mb {
    margin-bottom: 20px;
  }

  .thankyou-box-footer {
    margin-top: 50px;
    background-color: #333;
  }

  .thankyou-box-logo {
    display: block;
  }

  .details-form-door-preview {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  .please-send-btn-mob {
    display: block;
  }

  .details-form-title-mob {
    display: block;
  }

  .details-form__btn {
    margin-top: 10px;
  }

  .details-form__btn button {
    max-width: 100%;
  }

  .build__title-mobile.build__title-mobile--active.build__title-mobile--hiden {
    display: none;
  }

  .share-my-door-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 15px 0px;
  }

  .share-my-door-btn a {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000;
    font-size: 10px;
    letter-spacing: 0.11em;
    line-height: 12px;
  }

  .share-my-door-btn svg {
    margin-top: 10px;
  }

  .details-form__inner.dream-door {
    padding: 20px 20px 30px;
  }

  .panel-title {
    display: none;
  }

  .panel-title.mobile {
    display: block;
    background-color: #c7b299;
    font-size: 14px;
    letter-spacing: 0.2em;
    line-height: 16.8px;
    padding: 10px 12px;
  }

  .details-form__inner-text.mobile::before {
    display: none;
  }

  .details-form__inner-text.mobile p:last-child {
    display: block;
  }

  .details-form__inner-text.mobile p {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #fff;
  }

  .details-form__inner-text.mobile {
    display: block;
    background-color: #333;
    padding: 30px 45px;
  }

  .details-form__inner-text::before {
    display: block;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    top: initial;
  }

  .details-form__inner-text p:first-child::before {
    display: none;
  }

  .details-form__inner-text {
    background-color: #fff;
    padding: 30px 45px 50px;
  }

  .details-form__inner-text p {
    color: #333;
    font-size: 12px;
    line-height: 14.4px;
    text-align: center;
  }

  .details-form__inner-text p:last-child {
    display: none;
  }

  .build__bottpanel.stage-2 .build__bottpanel-btnfs {
    display: block;
  }

  .build__bottpanel.functional-btn-hidden {
    display: none;
  }

  .summary__img.summary-preview.gallery.mobile {
    display: block;
    order: initial;
    min-height: 100%;
    height: 100%;
  }

  .summary__img.summary-preview.gallery {
    display: none;
  }

  .summary-btn-box {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .summary-btn-box .global-btn {
    margin-bottom: 0px;
  }

  .build__bottpanel .build__bottpanel-btnfs {
    top: 0;
  }

  .thankyou-box-title {
    margin-top: 20px;
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 19.2px;
  }

  .thankyou-box-text {
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 19.2px;
  }

  .build__bottpanel.hide {
    display: none;
  }

  .build__items-inner.contain .build__item .build__item-img {
    height: auto !important;
  }

  .build__items-inner.medium-height .build__item .build__item-img {
    height: 150px;
  }

  .furniture-stack-box-item {
    background-color: transparent;
    padding: 0px;
  }

  .build__items-inner-box.frame-inner .build__items-inner.duplicate {
    grid-template-columns: repeat(4, 1fr);
  }

  .build__items-inner-box.mobile .build__items-inner {
    padding: 15px 15px 30px;
  }

  .build__items-inner-box.mobile.col2 .build__items-inner {
    grid-template-columns: repeat(2, 1fr);
  }

  .summary__panel-mobile {
    display: flex;
  }

  .summary__title.white {
    order: -1;
  }

  .main-box-asite.constructor {
    display: none;
  }

  .build__bottpanel.stage-2 .build__bottpanel-btnfs {
    top: -20px;
    position: absolute;
    width: auto;
    margin: 0;
    left: calc(50% - 20px);
  }

  .build__bottpanel.stage-2 {
    min-height: 35px;
    background-color: #000;
    position: relative;
  }

  .build__bottpanel .build__bottpanel-btnsummary {
    display: flex;
    width: auto;
    max-width: none;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .summary__img-box-btnNext {
    display: none;
  }

  .summary__panel .summary__panel-item {
    min-height: 40px;
    height: auto;
    padding: 0px 10px;
  }

  .summary__title {
    line-height: 25px;
  }

  .build-door__box-item-link {
    font-size: 14px;
    letter-spacing: -0.05em;
    line-height: 16.8px;
    justify-content: flex-start;
  }

  .favourite-style-banner__title {
    padding: 50px 20px 50px;
  }

  .favourite-style-banner .favourite-style-banner-img img {
    height: 100%;

    object-fit: cover;
  }

  .favourite-style-banner .favourite-style-banner-img {
    height: 220px;
  }

  .efficiency-info__second-img-box.height .efficiency-info__second-img {
    height: 150px;
  }

  .efficiency-info__first-item.height .efficiency-info__first-item-img {
    height: 150px;
  }

  .efficiency-info-title {
    text-align: left;
  }

  .efficiency-info__third .efficiency-info-text {
    text-align: left;
  }

  .efficiency-info__second .efficiency-info-text {
    text-align: left;
  }

  .efficiency-info-text {
    text-align: left;
  }

  .energy-efficiency-panel ul li {
    margin-top: 0px;
  }

  .energy-efficiency-panel ul li + li {
    border-top: 0.44px solid #999;
    margin-top: 10px;

    padding-top: 10px;
  }

  .energy-efficiency-panel ul li a {
    border-top: none;
  }

  .energy-efficiency-panel ul li a::before {
    right: 50px;
  }

  .main-box-content__inner-list.topPanel {
    height: auto;
    padding: 20px 0px 20px 20px;
  }

  .energy-efficiency-panel ul li {
    max-width: 100%;
  }

  .build-door__box-item-link {
    font-size: 14px;
  }

  .order-summary__footer .order-summary__footer-btn {
    padding: 0 15px;
  }

  .build__items-inner {
    padding-bottom: 30px;
  }

  .build__items-inner.owl-mobile {
    margin: auto 0;
    display: block;
  }

  .build__items-inner.owl-desktop {
    display: none;
  }

  .build__bottpanel-btnsummary {
    max-width: 50px;
  }

  .furniture-stack-btn button {
    display: none;
  }
}

@media (min-width: 501px) {
  .preview-door {
    display: none;
  }
}

@media (max-width: 425px) {
  .favourite-style-footer-link a {
    width: 100%;
  }

  .favourite-style-footer-link a .favourite-style-footer-link-img {
    height: 150px;
    display: none;
  }
}
