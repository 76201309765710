.canvas-container {
  display: none;
}

.summary__img.summary-preview .summary__img-box img {
  max-height: 100%;
  width: 100%;
  margin: 0 auto;
}
.summary__img.summary-preview .footer-panel {
  margin-top: 10px;
}
.simplebar-track {
  right: 2px !important;
}
.summary__panel-item-disable {
  opacity: 0.8;
}
.footer-panel__function-btn {
  text-transform: uppercase;
}
/*.summary__img.summary-preview {*/
/*  display: none;*/
/*}*/
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #464646;
  z-index: 1000;
  display: none;
  justify-content: center;
  align-items: center;

  opacity: 0;
}

.loader.show {
  display: flex;
  opacity: 1;
  transition: all 2s linear;
}
canvas#my-door,
canvas#my-door-mobile {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
}
.summary__img.summary-preview.gallery .canvas-container {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
  display: block;
}
.summary__img.summary-preview.gallery .canvas-container canvas {
  height: 100% !important;
}
.global-btn.global-btn-arrow {
  width: 100%;
  position: relative;
  max-width: 100%;
  background-color: #22b573;
  font-size: 10px;
  letter-spacing: 0.11em;
  line-height: 12px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 10px;
}
.global-btn.global-btn-arrow input[type='file'] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.main-box-content__inner-list-listbox li a.active {
  font-weight: 700;
}
.summary__img.summary-preview .footer-panel {
  z-index: 2;
}
.summary__img.summary-preview {
  overflow: hidden;
}
@media (max-width: 500px) {
  .main-box-content__inner.mobile-image .summary__con-inner {
    display: none;
  }
  .main-box-content__inner.mobile-image .summary__img.summary-preview {
    display: flex;
    height: 100%;
  }
}
.preview-door__img-box {
  overflow: hidden;
}
.preview-door__img-box img {
  object-fit: contain !important;
}

.build__items-inner.medium-height .build__item.glass .build__item-img {
  height: 120px;
}
.build__item.active::before {
  display: block;
  z-index: 100;
}
#canvas-builder {
  display: none;
}
.buid-door__header {
  text-align: center;
  padding-top: 50px;
  font-size: 35px;
}
.summary__img-box-zoom #background {
  /* transform: scale(1.5); */
}
.summary__img-box-zoom .build__bottpanel {
  position: absolute;
  bottom: 0;
}
.summary__img-box-zoom .summary__img-box {
  padding-bottom: 100px !important;
}
.summary__img-box-zoom #my-door {
  /* transform: scale(1.5); */
}

#cookie-notice {
  position: fixed;
  min-width: 100%;
  height: auto;
  z-index: 100000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  left: 0;
  bottom: 0;
  text-align: center;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, Arial, Roboto,
    'Helvetica Neue', sans-serif;
}
.cookie-notice-container {
  padding: 15px 30px;
  text-align: center;
  width: 100%;
  z-index: 2;
}
.cn-text-container {
  margin: 0 0 6px 0;
}
.cn-text-container,
.cn-buttons-container {
  display: inline-block;
}
.cn-close-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
  width: 15px;
  height: 15px;
  opacity: 0.5;
  padding: 10px;
  outline: none;
  cursor: pointer;
}
#cookie-notice .cn-button:not(.cn-button-custom) {
  font-family: -apple-system, BlinkMacSystemFont, Arial, Roboto,
    'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 0 0 0 10px;
  text-align: center;
  text-transform: none;
  display: inline-block;
  cursor: pointer;
  touch-action: manipulation;
  white-space: nowrap;
  outline: none;
  box-shadow: none;
  text-shadow: none;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  padding: 8.5px 10px;
  line-height: 1;
  color: inherit;
}
.cn-close-icon:hover {
  opacity: 1;
}
.cn-close-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
  width: 15px;
  height: 15px;
  opacity: 0.5;
  padding: 10px;
  outline: none;
  cursor: pointer;
}
.cn-close-icon:before {
  transform: rotate(45deg);
}
.cn-close-icon:before,
.cn-close-icon:after {
  position: absolute;
  content: ' ';
  height: 15px;
  width: 2px;
  top: 3px;
  background-color: rgba(128, 128, 128, 1);
}
.cn-close-icon:after {
  transform: rotate(-45deg);
}
.cn-close-icon:before,
.cn-close-icon:after {
  position: absolute;
  content: ' ';
  height: 15px;
  width: 2px;
  top: 3px;
  background-color: rgba(128, 128, 128, 1);
}
.cookie-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
}
